<template>
  <div class="schedule-page">
    <aside class="aside g-bg">
      <el-calendar v-model="date">
        <template slot="dateCell" slot-scope="{ data }">
          <div class="date-item">
            <div class="num">{{ data.day.split('-')[2] }}</div>
          </div>
        </template>
      </el-calendar>
    </aside>
    <main class="main g-bg" v-loading="loading">
      <div class="main-top">
        <el-radio-group v-model="currentScope" @change="onRefresh">
          <el-radio-button label="persion">仅我自己</el-radio-button>
          <el-radio-button label="dept">部门全体人员</el-radio-button>
        </el-radio-group>
        <el-button class="g-ml-20" style="margin-left: auto;" type="primary" @click="onExport">导出</el-button>
        <b-btn-create @click="onCreate">新建日程</b-btn-create>
      </div>
      <div class="main-bottom">
        <el-scrollbar y view-style="padding: 15px;">
          <div v-if="list && list.length === 0" class="g-empty"></div>
          <template v-if="currentScope === 'persion'">
            <div class="list-item list-item_flex" v-for="item in list" :key="item.id">
              <div class="content">
                {{ item.content }}
              </div>

              <div class="action-btn">
                <el-dropdown slot="reference" @command="onCommand($event, item)">
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="update" icon="el-icon-edit"> 修改 </el-dropdown-item>
                    <el-dropdown-item command="delete" icon="el-icon-delete"> 删除 </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="list-item" v-for="item in list" :key="item.id">
              <div class="list-item_name">{{ item.name }}</div>
              <div class="list-item_sub" v-for="it in item.schedules" :key="it.id">
                <span class="label">{{ it.schedule_date.split('-').slice(1).join('/') }}</span>
                <div class="value">
                  {{ it.content }}
                </div>
              </div>
            </div>
          </template>
        </el-scrollbar>
      </div>
    </main>

    <b-dialog ref="detailDialog" width="700px">
      <detail-view :data="formData" @success="onDetailSuccess"></detail-view>
    </b-dialog>
    <export-pannel ref="exportPannel"></export-pannel>
  </div>
</template>

<script>
import DetailView from '../components/schedule-detail.vue'
import ExportPannel from '../export-pannel.vue'
import { getAllReportPage, getReportPage, deleteReport } from '@/api/report/index'

export default {
  components: {
    DetailView,
    ExportPannel,
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      formData: {},
      list: [],
      start_date: '',
      end_date: '',
      currentScope: 'persion',
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    date: {
      handler(val) {
        this.start_date = this.$tools.dayjs(val).format('YYYY-MM-DD')
        this.end_date = this.$tools.dayjs(val).format('YYYY-MM-DD')
        this.onRefresh()
      },
      immediate: true,
    },
  },
  methods: {
    fetchList() {
      const params = {
        start_date: this.start_date,
        end_date: this.end_date,
        page: 1,
      }
      this.loading = true
      Promise.resolve()
        .then(() => {
          if (this.currentScope === 'persion') {
            params.user_id = this.userId
            return getAllReportPage(params)
          } else {
            params.department_id = this.deptId
            return getReportPage(params)
          }
        })
        .then(res => {
          this.page.total = res.total
          this.list = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onCreate() {
      this.formData = {
        schedule_date: this.$tools.dayjs(this.date).format('YYYY-MM-DD'),
        department_id: this.deptId,
        user_id: this.userId,
        content: '',
      }
      this.$refs.detailDialog.open()
    },
    onCommand(command, row) {
      if (command === 'update') {
        this.formData = {
          id: row.id,
          schedule_date: row.schedule_date,
          department_id: row.department_id,
          user_id: row.user_id,
          content: row.content,
        }
        this.$refs.detailDialog.open()
      } else {
        this.$confirm('确定要删除当前项吗', { type: 'warning' })
          .then(res => {
            return deleteReport(row.id)
          })
          .then(res => {
            this.$message.success('删除成功')
            this.fetchList()
          })
          .catch(() => {})
      }
    },
    onExport() {
      const params = {
        scope: this.currentScope,
        start_date: this.start_date,
        end_date: this.end_date,
      }
      this.$refs.exportPannel.open(params)
    },
    onDetailSuccess() {
      this.$refs.detailDialog.close()
      this.currentScope = 'persion'
      this.onRefresh()
    },
  },
}
</script>
<style lang="scss" scoped>
.schedule-page {
  display: flex;
  height: 100%;
  padding: 0 15px 15px;

  .aside {
    flex: 3;
    min-width: 0;
    margin-right: 15px;
  }

  .main {
    display: flex;
    flex: 2;
    flex-direction: column;
    min-width: 0;
    height: 100%;

    .main-top {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 15px;
      border-bottom: 1px solid #eee;
    }

    .main-bottom {
      flex: 1;
      min-height: 0;
    }
  }

  .time-box {
    font-size: 16px;
    font-weight: 600;
    line-height: 44px;
  }

  .date-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  /deep/ .is-selected .date-item .num {
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    background: #04a37e;
    border-radius: 50%;
  }

  .list-item {
    padding: 15px;
    margin-bottom: 15px;
    line-height: 24px;
    background: rgba($color: #dfe3e8, $alpha: 0.26);
    border-radius: 10px;

    &.list-item_flex {
      display: flex;

      .content {
        flex: 1;
        min-width: 0;
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #2b2d42;
      }
    }

    .list-item_name {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #2b2d42;
    }

    .list-item_sub {
      display: flex;
      width: 100%;
      margin-bottom: 5px;
      overflow: hidden;
      font-size: 14px;
      line-height: 24px;
      color: #747e8f;

      .label {
        width: 50px;
      }

      .value {
        flex: 1;
        min-width: 0;
        color: #2b2d42;
      }
    }
  }
}
</style>
